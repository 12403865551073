@import '~typeface-sacramento/index.css';

body {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 100vw;
    height: 100vh;

    margin: 0;

    overflow: hidden;

    background: linear-gradient(to top, #f47575 0%, #e5b1b1 75%);

    font-family: 'Sacramento', cursive;
    font-size: 2em;
}
