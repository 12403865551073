.box {
    width: 50%;
    height: 80%;

    margin: 0 auto;

    user-select: none;
    z-index: 1;

    background-color: rgba(255, 248, 248, 0.842);
    border-radius: 30px;
    border: 1px solid rgb(105, 105, 105);
    box-shadow: 0 0 10px 2px rgba(97, 97, 97, 0.451);

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2%;

    .wrapper {
        width: 100%;
        height: 100%;
        gap: 20px;
        
        .valWrapper {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            width: 100%;
            height: 100%;

            .valentineText {
                opacity: 0;
                animation: fadeIn 0.5s ease forwards; 
            }
        }
    }

    @keyframes fadeIn {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }
}
