.imageWrapper {
    position: fixed;

    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;

    img {
        width: 100%;
        height: 100%;
        object-fit: fill;
    }
}

.fadeIn {
    animation: fadeIn 1s ease-in forwards;
}

.fadeOut {
    animation: fadeOut 1s ease-in forwards 1s;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes fadeOut {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

.message {
    position: fixed;

    width: 100%;
    height: 100%;
    top: 0;
    left: 0;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    color: #ffffff;
    text-shadow: 0 0 10px #000000;
    font-weight: 700;
    margin-top: 20px;
    scale: 2;
    z-index: 2;

    text-align: center;
}

.hide {
    display: none;
}

.endWrapper {
    position: fixed;
    overflow-y: auto;

    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;

    display: flex;
    align-items: center;
    justify-content: center;

    background-color: rgb(248, 61, 210);
    z-index: 3;

    .textWrapper {
        scroll-snap-align: start;
        overflow-y: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        z-index: 5;

        h1 {
            font-size: 3rem;
            padding: 20px;
            text-align: center;
            color: #ffffff;
            text-shadow: 0 0 10px #000000;
            font-weight: 700;
            margin-top: 20px;
        }

        h2 {
            font-size: 3rem;
            line-height: 1.5;
            text-align: center;
            color: #ffffff;
            text-shadow: 0 0 10px #000000;
            font-weight: 700;
        }

        canvas {
            width: 100%;
            height: 100%;
            z-index: 100;
        }
    }
}
