// FullscreenModal.module.scss
.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;
}

.modal {
    background-color: rgba(255, 248, 248, 0.842);
    border-radius: 30px;
    border: 1px solid rgb(105, 105, 105);
    box-shadow: 0 0 10px 2px rgba(97, 97, 97, 0.451);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2%;
    gap: 20px;

    h1 {
        width: 80%;
        height: 100%;
        padding-top: 6%;
        margin-bottom: 15px;
        text-align: center;
    }

    .submitPass {
        height: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 80%;
        gap: 50px;
        padding-bottom: 10%;
    }
    button {
        width: 40%;
        padding: 10px;
        background-color: #ff66b2;
        color: #fff;
        border: none;
        border-radius: 5px;
        font-size: 0.925em;
        cursor: pointer;

        &:hover,
        &:focus {
            background-color: #fec6dc;
        }
    }
}
