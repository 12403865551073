.submitPass {
    position: relative;
    
    height: 100%;
    width: 100%;
    
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    overflow-y: auto;

    gap: 20px;

    padding-bottom: 10%;

    .buttonWrapper {
        width: 100%;
        height: 100%;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 50px;
   
        button {
            width: 100%;
            padding: 10px;
            background-color: #ff66b2;
            color: #fff;
            border: none;
            border-radius: 5px;
            font-size: 0.925em;
            cursor: pointer;
    
            &:hover,
            &:focus {
                background-color: #fec6dc;
            }
        }
    }
    

    .gif {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.catDancing {
    position: fixed;

    width: 100%;
    height: 100%;
    top: 0;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    h1 {
        color: #ff66b2;
        margin-top: 20px;
    }

    @keyframes rotate {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    }
}
