.wrapper {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.backgroundAnimations {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    position: fixed;

    z-index: -1;
}

h1 {
    text-align: center;
}
