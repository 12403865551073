// Prompt.module.scss

.wrapper {
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h1 {
        width: 80%;
        height: 50%;
        padding-top: 16%;
        margin-bottom: 15px;
        text-align: center;
    }

    .submitPass {
        height: 100%;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        width: 80%;
        gap: 50px;

        padding-bottom: 10%;

        input {
            letter-spacing: 15px;
            background: #ff66b2;
            color: #fff;
            border: 2px solid #ff66b2;
            border-radius: 5px;
            padding: 7px;
            font-size: 1.1em;

            text-align: center;
            width: 100%;
            outline: none;

            &:hover,
            &:focus {
                background-color: #fec6dc;
            }
        }

        button {
            width: 40%;
            padding: 10px;
            background-color: #ff66b2;
            color: #fff;
            border: none;
            border-radius: 5px;
            font-size: 0.925em;
            cursor: pointer;

            &:hover,
            &:focus {
                background-color: #fec6dc;
            }
        }
    }
}

.manCrying {
    position: fixed;

    width: 100%;
    height: 100%;
    top: 0;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    h1 {
        font-size: 2em;
        color: #ff66b2;
        margin-top: 20px;
    }
}
